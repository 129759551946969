import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Loading = ({ text }) => {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    if (!text) return;
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [seconds]);

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <div className="aai-hero-two-content d-flex text-center flex-column align-items-center justify-content-center">
            <h1 className="aai-hero-two-title">
              {/* High-Quality Way to Write your{" "} */}
              <span className="gradient-text typing-animation">
                {text ? `${text} ${seconds}secs` : `${t("loading")}...`}
              </span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
