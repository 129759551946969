import "./App.css";
import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./components/login/Login";
import Header from "./core/routes/header/Header";
import Footer from "./core/routes/footer/Footer";
import RequestErrorMessage from "./components/_components/request-error-message/RequestErrorMessage";
import {
  deleteCookie,
  getCookie,
  setCookie,
  showToast,
} from "./core/common-service/CommonService";
import { postRefreshToken, setDefaultHeader } from "./core/api/Api";
import { StoreContext } from "./core/context/StoreContext";
import Register from "./components/register/Register";
import ConfirmRegister from "./components/confirm-register/ConfirmRegister";
import Home from "./components/home/Home";
import Questions from "./components/home/general-categories/general-categories-options/questions/Questions";
import ProtectedRoute from "./core/routes/protected_route/ProtectedRoute";
import GeneralCategories from "./components/home/general-categories/GeneralCategories";
import QuestionCategories from "./components/home/general-categories/general-categories-options/GeneralCategoriesOptions";
import NotFound from "./core/routes/not-found/NotFound";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import ResetPassword from "./components/reset-password/ResetPassword";
import MyBooks from "./components/my-books/MyBooks";
import { useTranslation } from "react-i18next";
import Payment from "./components/payment/Payment";
import Profile from "./components/profile/Profile";
import GiftCard from "./components/gift-card/GiftCard";
import GiftCardPayment from "./components/gift-card/gift-card-payment/GiftCardPayment";
import ThankYouPurchase from "./components/thankyou/Purchase";
import { ThankYouRegister } from "./components/thankyou/Register";
import ScrollToTop from "./components/common/ScrollToTop";

const PreLoader = () => {
  const { setIsLoggedIn, setUser } = useContext(StoreContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const checkToken = () => {
    const token = sessionStorage.getItem("token") || getCookie("token");

    if (token) {
      setDefaultHeader("Bearer " + token);
      postRefreshToken()
        .then((res) => {
          const response = res.data;
          if (response?.success) {
            const token = response?.success?.token;
            const user = response?.success?.user;
            if (user?.is_verified) {
              setIsLoggedIn(true);
              setUser(user);
              setCookie("token", token);
            } else {
              showToast({
                type: "error",
                message: t("error.user_not_allowed"),
              });
            }
          } else {
            setIsLoggedIn(false);
            sessionStorage.clear();
            deleteCookie("token");
            navigate("/login");
          }
        })
        .catch((e) => {
          setIsLoggedIn(false);
          sessionStorage.clear();
          deleteCookie("token");
          navigate("/login");
        });
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkToken();
    return () => {};
  }, []);
};

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <PreLoader />
      <Header />
      <RequestErrorMessage />
      <main className="wrapper">
        <Routes>
          <Route
            exact
            path="/"
            element={
              // <ProtectedRoute>
              <Home />
              // </ProtectedRoute>
            }
          />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route
            exact
            path="/confirm-registration"
            element={<ConfirmRegister />}
          />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route
            exact
            path="/my-books"
            element={
              <ProtectedRoute>
                <MyBooks />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/general-categories/:service_id"
            element={
              // <ProtectedRoute>
              <GeneralCategories />
              // </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/general-categories-options/:service_id/:general_category_id"
            element={
              // <ProtectedRoute>
              <QuestionCategories />
              // </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/questions/:service_session_id"
            element={
              // <ProtectedRoute>
              <Questions />
              // </ProtectedRoute>
            }
          />
          {/* <Route
            exact
            path="/payment/:service_session_id/:plan_id"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          /> */}
          <Route
            exact
            path="/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route exact path="/gift-card" element={<GiftCard />} />
          <Route
            exact
            path="/gift-card-payment"
            element={<GiftCardPayment />}
          />
          <Route
            exact
            path="/thankyou/purchase"
            element={<ThankYouPurchase />}
          />
          <Route
            exact
            path="/thankyou/register"
            element={<ThankYouRegister />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}
